/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import classNames from 'classnames/bind';

import Spinner from '@components/common/responsive/Spinner/Spinner';

import Button, { ButtonProps } from './Button';

import style from './ButtonTertiary.module.scss';

const setClass = classNames.bind(style);

interface ButtonTertiaryProps {
  className?: string;
}

const ButtonTertiary = ({
  className,
  ...props
}: ButtonProps & ButtonTertiaryProps) => {
  return (
    <Button
      {...props}
      className={setClass(
        {
          tertiary: true,
          isDisabled: props.isDisabled,
          big: !props.medium && !props.small,
          medium: props.medium,
          small: props.small,
        },
        className
      )}
      loader={<Spinner blue />}
    />
  );
};

export default ButtonTertiary;

export enum COLOR_TYPES {
  none = 'none',
  contentZO = 'var(--kp-color-content-ZO)',
  fillZO = 'var(--kp-color-fill-ZO)',
  fillAccent = 'var(--kp-color-fill-accent)',
  fillAccentPromo = 'var(--kp-color-fill-accent-promo)',
  fillInteractiveSecondary = 'var(--kp-color-fill-interactive-secondary)',
  fillInteractivePrimary = 'var(--kp-color-fill-interactive-primary)',
  contentOnFill = 'var(--kp-color-content-onFill)',
  bgInfo = 'var(--kp-color-bg-info)',
  bgPrimary = 'var(--kp-color-bg-primary)',
  bgSecondary = 'var(--kp-color-bg-secondary)',
  bgSuccess = 'var(--kp-color-bg-success)',
  bgSuccessProm = 'var(--kp-color-bg-success-prom)',
  bgAlert = 'var(--kp-color-bg-alert)',
  bgAlertProm = 'var(--kp-color-bg-alert-prom)',
  bgWarn = 'var(--kp-color-bg-warning)',
  bgWarnProm = 'var(--kp-color-bg-warning-prom)',
  formBgInput = 'var(--kp-color-form-bg-input)',
  formBgSelected = 'var(--kp-color-form-bg-selected)',
  formBgSelectedAlt = 'var(--kp-color-form-bg-selected-alt)',
  formBgControl = 'var(--kp-color-form-bg-control)',
  formBgControlHover = 'var(--kp-color-form-bg-control-hover)',
  formBorderDefault = 'var(--kp-color-form-border-default)',
  formBorderActive = 'var(--kp-color-form-border-active)',
  formBorderError = 'var(--kp-color-form-border-error)',
  formBorderHover = 'var(--kp-color-form-border-hover)',
  formBorderDisabled = 'var(--kp-color-form-border-disabled)',
  fillDisabled = 'var(--kp-color-fill-disabled)',
  fillPositive = 'var(--kp-color-fill-positive)',
  fillEcomm = 'var(--kp-color-fill-ecomm)',
  contentInteractivePrimary = 'var(--kp-color-content-interactive-primary)',
  contentInteractiveSecondary = 'var(--kp-color-content-interactive-secondary)',
  contentPositive = 'var(--kp-color-content-positive)',
  contentPrice = 'var(--kp-color-content-price)',
  contentRegularPrimary = 'var(--kp-color-content-regular-primary)',
  contentRegularSecondary = 'var(--kp-color-content-regular-secondary)',
  contentRegularTertiary = 'var(--kp-color-content-regular-tertiary)',
  contentAccentPrimary = 'var(--kp-color-content-accent-primary)',
  contentAccentSecondary = 'var(--kp-color-content-accent-secondary)',
  contentPoslovi = 'var(--kp-color-content-poslovi)',
  disabledDim = 'var(--kp-color-disabled-dim)',
  disabledProm = 'var(--kp-color-disabled-prom)',
  contentWarning = 'var(--kp-color-content-warning)',
  fillNeutralInverted = 'var(--kp-color-fill-neutral-inverted)',
  fillNeutral = 'var(--kp-color-fill-neutral)',
  contentAkcijski = 'var(--kp-color-content-akcijski)',
  contentAlert = 'var(--kp-color-content-alert)',
  contentDim = 'var(--kp-color-content-dim)',
  contentEcommOnFill = 'var(--kp-color-content-ecomm-onFill)',
  contentWarningAccent = 'var(--kp-color-content-warning-accent)',
  borderNeutralProm = 'var(--kp-color-border-neutral-prom)',
  formBgDisabled = 'var(--kp-color-form-bg-disabled)',
  borderNeutral = 'var(--kp-color-border-neutral)',
  contentSuccess = 'var(--kp-color-content-success)',
  brandFillBlue = 'var(--kp-color-brand-fill-blue)',
  contentInfo = 'var(--kp-color-content-info)',
  customDexpressRed = 'var(--kp-color-custom-dexpress-red)',
  customDexpressBlack = 'var(--kp-color-custom-dexpress-black)',
  primaryDexpressBlack = 'var(--color-primary-black)',
  fillInteractiveSecondaryHover = 'var(--kp-color-fill-interactive-secondary-hover)',
}

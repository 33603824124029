import React from 'react';

import { COLOR_TYPES } from '@lib/constants/colors.constants';

import { HomeRoute, KPHelpCenterFeedbackRoute } from '@server/routes';

import ButtonPrimaryBlue from '@components/controls/Button/ButtonPrimaryBlue';
import ButtonTertiary from '@components/controls/Button/ButtonTertiary';
import BrokenMagnifierIconExtraLarge from '@components/icons/common/BrokenMagnifierIconExtraLarge';

import style from './ErrorNotFound.module.scss';

const ErrorNotFound = () => (
  <div className={`${style.container} ${style.centerAlign}`}>
    <div className={style.content}>
      <BrokenMagnifierIconExtraLarge
        stroke={COLOR_TYPES.contentAccentSecondary}
      />
      <h1>Stranica koju tražite ne postoji</h1>
      <p>ili je url adresa neispravna</p>

      <ButtonPrimaryBlue href={HomeRoute.generateUrl()}>
        Nazad na početnu stranu
      </ButtonPrimaryBlue>

      <div className={style.link}>
        <ButtonTertiary href={KPHelpCenterFeedbackRoute.generateUrl()}>
          Ili posetite naš Help Centar
        </ButtonTertiary>
      </div>
    </div>
  </div>
);

export default ErrorNotFound;

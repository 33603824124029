import dynamic from 'next/dynamic';

import Layout from '@layouts/responsive/Layout/Layout';

const MobileErrorLayout = dynamic(
  () => import('@layouts/mobile/MobileErrorLayout/MobileErrorLayout')
);

export const mobileErrorLayout = (page: React.ReactNode) => (
  <Layout>
    <MobileErrorLayout>{page}</MobileErrorLayout>
  </Layout>
);

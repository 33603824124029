import dynamic from 'next/dynamic';
import Layout from '@layouts/responsive/Layout/Layout';

const ErrorLayout = dynamic(
  () => import('@layouts/desktop/ErrorLayout/ErrorLayout')
);

export const errorLayout = (page: React.ReactNode) => (
  <Layout>
    <ErrorLayout>{page}</ErrorLayout>
  </Layout>
);

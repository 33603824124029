import React from 'react';
import classNames from 'classnames/bind';

import { COLOR_TYPES } from '@lib/constants/colors.constants';

import style from './asIcon.module.scss';

const setClass = classNames.bind(style);

type AsIconProps = {
  fill?: COLOR_TYPES | 'none';
  stroke?: COLOR_TYPES | 'none';
  redFill?: boolean;
  redStroke?: boolean;
  blueFill?: boolean;
  whiteFill?: boolean;
  whiteStroke?: boolean;
  blueStroke?: boolean;
  blackFill?: boolean;
  blackStroke?: boolean;
  greyFill?: boolean;
  grey3Fill?: boolean;
  greyStroke?: boolean;
  lightGreyStroke?: boolean;
  brightGreyStroke?: boolean;
  lightBlueFill?: boolean;
  lightBlueStroke?: boolean;
  greenStroke?: boolean;
  greenFill?: boolean;
  greenSecondaryStroke?: boolean;
  greenSecondaryFill?: boolean;
  darkGreenStroke?: boolean;
  brownStroke?: boolean;
  yellowStroke?: boolean;
  lightGreyFill?: boolean;
  forceWhite?: boolean;
  grey3Stroke?: boolean;
  brownFill?: boolean;
  yellowFill?: boolean;
  strokeWidth?: number;
  brightBlueFill?: boolean;
  isSmall?: boolean;
  bgRedFill?: boolean;
  bgRedStroke?: boolean;
  grayscale2Stroke?: boolean;
};

const asIcon = (WrappedComponent) => {
  const AsIcon = (props: AsIconProps) => {
    const {
      fill = COLOR_TYPES.none,
      stroke = COLOR_TYPES.none,
      strokeWidth = undefined,
      isSmall = false,
    } = props;

    const className = setClass({
      svg: true,
      isSmall,
    });

    return (
      <WrappedComponent
        className={className}
        strokeWidth={strokeWidth}
        fill={fill}
        stroke={stroke}
        {...props}
      />
    );
  };

  AsIcon.displayName = WrappedComponent.name;

  return AsIcon;
};

export default asIcon;

import React from 'react';

import { IconProps } from '@type/icons';

import asIcon from '../asIcon';

const BrokenMagnifierIconExtraLarge = ({
  className,
  stroke,
  fill,
}: IconProps) => (
  <svg
    width="65"
    height="64"
    viewBox="0 0 65 64"
    fill={fill}
    stroke={stroke}
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g clipPath="url(#clip0_2_2550)">
      <path
        d="M36.3388 48.6703C48.8036 43.3675 54.6245 28.9522 49.3217 16.457C44.0188 3.99222 29.6036 -1.82873 17.1388 3.47413C4.64356 8.77698 -1.17739 23.1922 4.12547 35.6875C9.42832 48.1522 23.8436 53.9732 36.3388 48.6703Z"
        strokeWidth="3.02381"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M44.0798 43.4286L63.1274 62.4762"
        strokeWidth="3.02381"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.7921 1.88953L33.474 11.0933L24.3312 16.2438L26.7388 23.5886L21.5579 28.16"
        strokeWidth="3.02381"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_2_2550">
        <rect
          width="64"
          height="64"
          fill="white"
          transform="translate(0.651184)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default asIcon(BrokenMagnifierIconExtraLarge);

import React, { useEffect } from 'react';

import { useAppDispatch } from '@hooks/redux/useAppDispatch';

import { fetchToken } from '@store/auth/thunk';
import { fetchGeneralGroupedInfo } from '@store/generalStats/thunk';

import ErrorNotFound from '@pages/responsive/ErrorNotFound/ErrorNotFound';

import { errorLayout } from '@layouts/desktop/ErrorLayout/layout';
import { mobileErrorLayout } from '@layouts/mobile/MobileErrorLayout/layout';

const ErrorNotFoundPageContainer = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(fetchGeneralGroupedInfo());
    dispatch(fetchToken());
  }, [dispatch]);

  return <ErrorNotFound />;
};

ErrorNotFoundPageContainer.getLayout = errorLayout;
ErrorNotFoundPageContainer.getLayoutMobile = mobileErrorLayout;

export default ErrorNotFoundPageContainer;
